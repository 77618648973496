import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    title: 'Dizze - Home',
    path: '',
    loadComponent: () => import('./pages/home').then((m) => m.HomeComponent)
  },
  {
    title: 'Dizze - Galerie',
    path: 'galerie',
    loadComponent: () => import('./pages/galerie').then((m) => m.GalleryComponent)
  },
  {
    title: 'Dizze - Forum',
    path: 'forum',
    loadComponent: () => import('./pages/forum').then((m) => m.ForumComponent)
  },
  {
    title: 'Dizze - Games',
    path: 'games',
    loadComponent: () => import('./pages/games').then((m) => m.GamesComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./pages/games').then((m) => m.SelectGameComponent)
      },
      {
        path: 'create',
        loadComponent: () => import('./pages/games').then((m) => m.CreateGameComponent)
      },
      {
        title: 'Quiz Bowl',
        path: 'quiz-bowl',
        loadComponent: () => import('./pages/games').then((m) => m.QuizBowlComponent)
      },
      {
        title: 'Cards Against Humanity',
        path: 'cards-against-humanity',
        loadComponent: () => import('./pages/games').then((m) => m.CardsAgainstHumanityComponent)
      }
    ]
  }
];
