import { DOCUMENT, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, Signal, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconButton } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatRadioButton } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { CookieService } from 'ngx-cookie-service';
import { filter, map } from 'rxjs';

import { LoaderComponent } from 'app/components';

import { environment } from 'environments/environment';


export interface Link {
  name: 'Home' | 'Games' | 'Galerie' | 'Forum';
  active: Signal<boolean>;
  disabled: boolean;
}

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatToolbarModule,
    RouterLink,
    RouterLinkActive,
    MatMenuModule,
    MatIconButton,
    FaIconComponent,
    MatRadioButton,
    MatTabsModule,
    NgOptimizedImage,
    MatProgressSpinner,
    LoaderComponent
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  readonly #document = inject(DOCUMENT);

  readonly #router = inject(Router);

  readonly #cookieService = inject(CookieService);

  readonly #url = toSignal(this.#router.events.pipe(
    filter((event) => event instanceof NavigationEnd),
    map((event) => {

      if (event instanceof NavigationEnd) {

        return event.url.split('/')[1];

      }
      return '';

    })
  ));

  readonly links: Link[] = [
    {
      name: 'Home',
      active: computed(() => this.#url() === ''),
      'disabled': false
    },
    {
      name: 'Games',
      active: computed(() => this.#url() === 'games'),
      disabled: false
    },
    {
      name: 'Galerie',
      active: computed(() => this.#url() === 'galerie'),
      disabled: false
    },
    {
      name: 'Forum',
      active: computed(() => this.#url() === 'forum'),
      disabled: true
    }
  ];

  readonly icons = {
    palette: faPalette
  };

  loadingRoute = signal(false);

  ngOnInit () {
    this.#router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRoute.set(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRoute.set(false);
      }
    });
    if (this.#cookieService.check('theme')) {
      this.switchTheme(this.#cookieService.get('theme'));
    }
  }

  changeRoute (link: Link): void {
    void this.#router.navigate([link.name === 'Home' ? '' : link.name.toLowerCase()]);
  }

  switchTheme (theme: string): void {
    const themeLink = this.#document.getElementById('app-theme') as HTMLLinkElement;

    themeLink.href = theme + '.css';
    this.#cookieService.set('theme', theme, { expires: 400 });
  }

  protected readonly environment = environment;
}
