<div class="waveWrapper waveAnimation fixed -z-10 overflow-hidden left-0 right-0 bottom-0 top-0 m-auto">
  <div class="waveWrapperInner bgTop w-full overflow-hidden opacity-50">
    <div class="wave waveTop"></div>
  </div>
  <div class="waveWrapperInner bgMiddle opacity-75">
    <div class="wave waveMiddle"></div>
  </div>
  <div class="waveWrapperInner bgBottom">
    <div class="wave waveBottom"></div>
  </div>
</div>
<div class="fixed w-full z-50">
  <mat-toolbar>
    <div class="toolbar-row w-full grid">
      <a routerLink="" class="justify-self-start">
        <img ngSrc="/assets/images/logo75.webp" alt="logo" height="75" width="75"
             [placeholder]="environment.imgPlaceholder" priority/>
      </a>
      <div class="my-auto">
        <nav mat-tab-nav-bar class="justify-self-center" [tabPanel]="tabPanel">
          @for (link of links; track link.name) {
            <a mat-tab-link [active]="link.active()" [disabled]="link.disabled" (click)="changeRoute(link)">{{ link.name }}</a>
          }
        </nav>
        <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
      </div>
      <div class="flex flex-row items-center justify-self-end">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Theme">
          <fa-icon [icon]="icons.palette"/>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="switchTheme('spring-green-theme')">Spring Green</button>
          <button mat-menu-item (click)="switchTheme('cyan-theme')">Cyan</button>
          <button mat-menu-item (click)="switchTheme('magenta-theme')">Magenta</button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar>
</div>
<div class="w-full h-full py-24 px-10">
  <router-outlet/>
</div>
<app-loader [active]="loadingRoute()"/>
